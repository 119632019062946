import React from 'react'
import { navigate } from 'gatsby-link'
import { Link } from 'gatsby'
import Layout from '../../components/Layout'
import Helmet from 'react-helmet'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Entry extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <main className="l-main">
          <div className="l-container">
            <Helmet titleTemplate="%s | 株式会社アーバ">
              <title>エントリーフォーム</title>
              <meta
                name="description"
                content="エントリーフォームページ"
              />
            </Helmet>
            <h1 className="c-pageTitle" data-spacingsp="mb7">
              <span className="c-pageTitle__container">
                <span className="c-pageTitle__textJa">エントリーフォーム</span>
                <span className="c-pageTitle__textEn">ENTRY</span>
              </span>
            </h1>
          </div>
          <div className="l-container--wide">
            <ul className="p-breadCrumbs">
              <li className="p-breadCrumbs__item"><Link to="/" className="p-breadCrumbs__link">ホーム</Link></li>
              <li className="p-breadCrumbs__item"><Link to="/recruit/" className="p-breadCrumbs__link">採用情報</Link></li>
              <li className="p-breadCrumbs__item isCurrent">エントリーフォーム</li>
            </ul>
          </div>
          <div className="l-container">
            <p className="u-alignCenter u-alignSpLeft u-lineHeight200" data-spacing="mb8">
              弊社の採用情報をご覧いただきありがとうございます。<br />
              各項目を入力し、「入力内容を確認する」ボタンを押してください。
            </p>
            <form
              name="entry"
              method="post"
              action="/recruit/thanks/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
            >
              <div className="p-contact">
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="p-contact__field">
                  <label className="p-contact__label" htmlFor={'name'}>
                    お名前
                    <span className="p-contact__required">必須</span>
                  </label>
                  <div className="p-contact__control">
                    <input
                      className="c-inputText p-contact__input--narrow"
                      type={'text'}
                      name={'name'}
                      onChange={this.handleChange}
                      id={'name'}
                      required={true}
                      placeholder={'例）山田太郎'}
                      autoComplete={"name"}
                    />
                  </div>
                </div>
                <div className="p-contact__field">
                  <label className="p-contact__label" htmlFor={'kana'}>
                    ふりがな
                    <span className="p-contact__required">必須</span>
                  </label>
                  <div className="p-contact__control">
                    <input
                      className="c-inputText p-contact__input--narrow"
                      type={'text'}
                      name={'kana'}
                      onChange={this.handleChange}
                      id={'kana'}
                      required={true}
                      placeholder={'例）やまだたろう'}
                    />
                  </div>
                </div>
                <div className="p-contact__field">
                  <label className="p-contact__label" htmlFor={'email'}>
                    メールアドレス<span className="p-contact__required">必須</span>
                  </label>
                  <div className="p-contact__control">
                    <input
                      className="c-inputText p-contact__input--medium"
                      type={'email'}
                      name={'email'}
                      onChange={this.handleChange}
                      id={'email'}
                      required={true}
                      placeholder={'例）xxx@arbor.jp'}
                      autoComplete={"email"}
                    />
                  </div>
                </div>
                <div className="p-contact__field">
                  <div className="p-contact__label" data-spacingsp="pv3">
                    応募職種<span className="p-contact__required">必須</span>
                  </div>
                  <div className="p-contact__control">
                    <label className="c-radio" htmlFor={'programer'}>
                      <input
                          className="c-radio__input"
                          type={"radio"}
                          name={"occupation"}
                          onChange={this.handleChange}
                          id="programer"
                          required={true}
                      />
                      <span className="c-radio__text">
                        プログラマー
                      </span>
                    </label>
                  </div>
                </div>
                <div className="p-contact__field">
                  <div className="p-contact__label" data-spacingsp="pt3">
                    職務経歴書<span className="p-contact__required">必須</span>
                  </div>
                  <div className="p-contact__control">
                    <label className="c-file">
                      <input
                        className="c-file__input"
                        type={"file"}
                        name={"curriculum-vitae"}
                        onChange={this.handleChange}
                        id="curriculum-vitae"
                        required={true}
                      />
                      
                    </label>
                  </div>
                </div>
                <div className="p-contact__field">
                  <div className="p-contact__label">
                    履歴書
                  </div>
                  <div className="p-contact__control">
                    <label className="c-file">
                      <input
                        className="c-file__input"
                        type={"file"}
                        name={"resume"}
                        onChange={this.handleChange}
                        id="resume"
                        required={false}
                      />
                      
                    </label>
                  </div>
                </div>
                <div className="p-contact__field">
                  <label className="p-contact__label" htmlFor={'url'} data-spacingsp="pt3">
                    プロフィール<br className="u-mqHidden--sm"/>
                    ポートフォリオ<br className="u-mqHidden--sm"/>
                    実績等へのリンク
                  </label>
                  <div className="p-contact__control">
                    <textarea
                      className="c-textArea p-contact__input p-contact__textArea"
                      name={'url'}
                      onChange={this.handleChange}
                      id={'url'}
                      required={false}
                      placeholder={'URL'}
                    />
                  </div>
                </div>
                <div className="p-contact__field">
                  <label className="p-contact__label" htmlFor={'comment'} data-spacingsp="pt3">
                    コメント
                  </label>
                  <div className="p-contact__control">
                    <textarea
                      className="c-textArea p-contact__input p-contact__textArea"
                      name={'comment'}
                      onChange={this.handleChange}
                      id={'comment'}
                      required={false}
                      placeholder={'ご自由にご入力ください'}
                    />
                  </div>
                </div>
                <div className="u-alignCenter">
                  <p data-spacing="mt8 mb3" data-spacingsp="mb8">
                    <a
                      href="/privacypolicy"
                      className="u-textDecoration u-font--marineBlue"
                      target="_blank"
                    >
                      プライバシーポリシー
                    </a>
                  </p>
                  <p data-spacing="mb8" className="u-displayInlineBlock">
                    <label className="c-checkBox p-contact__privacyCheck">
                      <input
                        className="c-checkBox__input p-contact__privacyCheckInput"
                        type={"checkbox"}
                        name={"privacyCheck"}
                        onChange={this.handleChange}
                        id="privacyCheck"
                        required={true}
                        />

                      <span className="c-checkBox__text p-contact__privacyCheckText">
                        プライバシーポリシーに同意する
                      </span>
                    </label>
                  </p>
                  <div data-spacingsp="ph4">
                    <button className="c-button--skewBorderOrange p-contact__submit" type="submit">
                      送信
                      <span className="c-icon--arrow c-button__arrow" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </main>
      </Layout>
    )
  }
}
